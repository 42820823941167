import { useEffect } from 'react'
import { useDocumentTitle } from './DocumentTitleContext'

interface TerminalDocumentTitleProps {
  title?: string
  suffix?: string
}

const TerminalDocumentTitle = ({
  title,
  suffix
}: TerminalDocumentTitleProps) => {
  const { setTitle, setTitleSuffix } = useDocumentTitle()
  useEffect(() => {
    if (title) setTitle(title)
    if (suffix) setTitleSuffix(suffix)
  }, [title, suffix])

  return <></>
}

export default TerminalDocumentTitle
