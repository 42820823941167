import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const URL_PART_SEPARATOR = '/'

const LEGACY_PATH_REGEX = /^\/([0-9a-fA-F-]{36})\/([0-9a-fA-F-]{36})(.*)$/

const LEGACY_PATH_MAPPINGS = {
  '/yard/gate-drivers': '/drivers',
  '/yard/cargo-asset': '/cargo-assets',
  '/gate/queue': '/queues',
  '/gate/transactions': '/transactions'
}

const LegacyRedirect = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const match = location.pathname.match(LEGACY_PATH_REGEX)
    if (match) {
      const [_, site_id, __, rest] = match

      let newRest = rest
      if (rest in LEGACY_PATH_MAPPINGS) {
        newRest =
          LEGACY_PATH_MAPPINGS[rest as keyof typeof LEGACY_PATH_MAPPINGS]
      }

      const newPath = `/sites/${site_id}${newRest}`
      navigate(newPath, { replace: true })
    }
  }, [location.pathname, navigate])

  return null
}

export default LegacyRedirect
