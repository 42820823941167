export const ENTERPRISE_ROUTE_PREFIX = '/enterprise'

const ROUTES = {
  HOME: '/',
  PROFILE: '/profile',

  GATE: '/sites/:site_id/',
  QUEUES: '/sites/:site_id/queues',
  TRANSACTIONS: '/sites/:site_id/transactions',
  DRIVERS: '/sites/:site_id/drivers',
  CARGO_ASSETS: '/sites/:site_id/cargo-assets',
  ISR: '/sites/:site_id/isr',

  ENTERPRISE_USERS: `${ENTERPRISE_ROUTE_PREFIX}/users`,
  ENTERPRISE_INVITES: `${ENTERPRISE_ROUTE_PREFIX}/invites`,
  ENTERPRISE_TRANSACTIONS: `${ENTERPRISE_ROUTE_PREFIX}/transactions`,
  ENTERPRISE_DRIVERS: `${ENTERPRISE_ROUTE_PREFIX}/drivers`,
  ENTERPRISE_CARGO_ASSETS: `${ENTERPRISE_ROUTE_PREFIX}/cargo-assets`,
  ENTERPRISE_ISR: `${ENTERPRISE_ROUTE_PREFIX}/isr`
}

export default ROUTES
