import * as Sentry from '@sentry/react'
import { Route, Routes } from 'react-router-dom'

import { ErrorTemplate, MainTemplate } from '@/components/templates'
import config from '@/config'
import {
  GateDriversTemplate,
  GateQueueTemplate,
  GateTemplate,
  GateTransactionTemplate
} from '@/features/gate'
import { HomeTemplate } from '@/features/home'
import { ISRTemplate } from '@/features/isr'
import { ProfileTemplate } from '@/features/profile'
import { UserManagementTemplate } from '@/features/user-management'
import { CargoAssetTemplate } from '@/features/yard'
import ROUTES from '@/router/routes'
import { ErrorType } from '@/types/enums/ui'
import LegacyRedirect from './LegacyRedirect'

const SentryRoutes = !config.isDev
  ? Sentry.withSentryReactRouterV6Routing(Routes)
  : Routes

const RootRouter = () => {
  return (
    <SentryRoutes>
      <Route element={<MainTemplate />}>
        {/* Site portal routes */}
        <Route path={ROUTES.GATE} element={<GateTemplate />}>
          <Route path={ROUTES.QUEUES} element={<GateQueueTemplate />} />
          <Route
            path={ROUTES.TRANSACTIONS}
            element={<GateTransactionTemplate />}
          />
        </Route>
        <Route path={ROUTES.DRIVERS} element={<GateDriversTemplate />} />
        <Route path={ROUTES.ISR} element={<ISRTemplate />} />
        <Route path={ROUTES.CARGO_ASSETS} element={<CargoAssetTemplate />} />

        {/* Enterprise routes */}
        <Route
          path={ROUTES.ENTERPRISE_USERS}
          element={<UserManagementTemplate />}
        />
        <Route
          path={ROUTES.ENTERPRISE_TRANSACTIONS}
          element={<GateTransactionTemplate enterpriseMode />}
        />
        <Route
          path={ROUTES.ENTERPRISE_DRIVERS}
          element={<GateDriversTemplate enterpriseMode />}
        />
        <Route
          path={ROUTES.ENTERPRISE_CARGO_ASSETS}
          element={<CargoAssetTemplate enterpriseMode />}
        />
        <Route
          path={ROUTES.ENTERPRISE_ISR}
          element={<ISRTemplate enterpriseMode />}
        />
        <Route
          path={ROUTES.ENTERPRISE_INVITES}
          element={<UserManagementTemplate />}
        />

        {/* Common routes */}
        <Route path={ROUTES.HOME} element={<HomeTemplate />} />
        <Route path={ROUTES.PROFILE} element={<ProfileTemplate />} />
        <Route path="/*" element={<LegacyRedirect />} />
        <Route path="*" element={<ErrorTemplate type={ErrorType.NotFound} />} />
      </Route>
    </SentryRoutes>
  )
}

export default RootRouter
