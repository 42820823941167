import { useCallback, useMemo, useState } from 'react'

import IsrData from '@/assets/icons/isr_data.svg?react'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { UpdateIsrReportModal } from '@/features/isr'

import {
  GetEnterpriseISRReportsQuery,
  GetISRReportsQuery
} from '@/features/isr/api/queries'
import {
  isrColumns,
  isrHighOrderColumns,
  ISR_REPORTS_COLUMNS_VERSION
} from '@/features/isr/utils'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { TableActions } from '@/types/enums/table'
import {
  DEFAULT_TABLE_REQUEST_DETAILS,
  TableRequestDetails
} from '@/types/interfaces/table'
import { useQuery } from '@apollo/client'

import {
  IsrEventPair,
  ListIsrRecordsInput,
  OrderByIsrRecordColumn,
  SearchDomainEventColumns,
  StringOperator
} from '@/__generated__/graphql'
import { prepareSortForGraphQlApi } from '@/api'
import TerminalDocumentTitle from '@/components/contexts/Title/TerminalDocumentTitle'
import PieChart from '@/components/organisms/Table/Rollups/Pie/PieChart'
import { ROLLUP_WIDGET_COLORS } from '@/components/organisms/Table/Rollups/Shared/Colors/RollupColors'
import RollupStat from '@/components/organisms/Table/Rollups/Stats/RollupStat'
import TimeseriesChart from '@/components/organisms/Table/Rollups/Timeseries/TimeseriesChart'
import { useCheckMyPermissionsQuery } from '@/features/auth/api'
import { LaneRelation, PermissionResourceType } from '@/features/auth/enums'
import { preparePermissionResourceQuery } from '@/features/auth/helpers'
import { PortalTypes } from '@/types/enums/global'
import { EmissionType } from '@/types/enums/transactionDetails'
import {
  dateRangeFilter,
  emissionTypeFilter,
  fuelTypeFilter,
  mismatchFilter,
  siteFilter,
  weightClassFilter
} from '@/utils/table'
import useIsrRollups from '../../hooks/useIsrRollups'

interface Props {
  enterpriseMode?: boolean
}

const TABLE_ID = 'isr-records'
const ENTERPRISE_TABLE_ID = 'enterprise-isr-records'

const ISRTemplate = (props: Props) => {
  const { enterpriseMode = false } = props

  const [selectedItem, setSelectedItem] = useState<IsrEventPair | undefined>()
  const { selectedPortal, org, portals } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [
    { currentPage, search, sortBy, filters, dateRange },
    setRequestDetails
  ] = useState<TableRequestDetails>(DEFAULT_TABLE_REQUEST_DETAILS)

  const { siteIDs, ...remainingFilters } = filters || {}

  const baseQueryInput: Omit<ListIsrRecordsInput, 'siteID'> = {
    organizationID: org_id,
    currentPage,
    itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    search: {
      fields: [
        SearchDomainEventColumns.PowerUnitOwnerId,
        SearchDomainEventColumns.PowerUnitLicensePlateNumber,
        SearchDomainEventColumns.PowerUnitCarrierUsdot,
        SearchDomainEventColumns.PowerUnitVin,
        SearchDomainEventColumns.AccountName,
        SearchDomainEventColumns.DriverName,
        SearchDomainEventColumns.DriverLicenseNumber,
        SearchDomainEventColumns.CargoAssetOwnerId,
        SearchDomainEventColumns.CargoAssetLicensePlateNumber,
        SearchDomainEventColumns.ShipmentNumber,
        SearchDomainEventColumns.SealNumbers
      ],
      operator: StringOperator.Contains,
      value: search
    },
    order: prepareSortForGraphQlApi<OrderByIsrRecordColumn>(sortBy),
    filter: remainingFilters,
    dateRange
  }

  const {
    loadingEmissions,
    emissionDataCount,
    loadingNearZeroEmissions,
    nearZeroEmissionDataCount,
    loadingZeroEmissions,
    zeroEmissionDataCount
  } = useIsrRollups({
    query: baseQueryInput,
    enterpriseMode,
    filters: remainingFilters
  })

  const { loading, data, refetch } = useQuery(GetISRReportsQuery, {
    variables: {
      input: {
        ...baseQueryInput,
        siteID: site_id
      }
    },
    skip: enterpriseMode
  })

  const { loading: enterpriseLoading, data: enterpriseData } = useQuery(
    GetEnterpriseISRReportsQuery,
    {
      variables: {
        input: {
          ...baseQueryInput,
          siteIDs: siteIDs?.values
        }
      },
      skip: !enterpriseMode
    }
  )

  const reports = enterpriseMode
    ? enterpriseData?.listEnterpriseISRRecords?.ISRRecords || []
    : data?.listISRRecords?.ISRRecords || []

  const pagination = enterpriseMode
    ? enterpriseData?.listEnterpriseISRRecords?.pagination
    : data?.listISRRecords?.pagination

  const laneId = reports?.[0]?.ciMetadata?.laneId

  const { currentData: updatePermission, isFetching } =
    useCheckMyPermissionsQuery(
      {
        orgId: org_id,
        relation: LaneRelation.UpdateIsrRecord,
        resource: preparePermissionResourceQuery(
          PermissionResourceType.Lane,
          laneId
        )
      },
      { skip: !laneId || enterpriseMode }
    )

  const isUpdateAllowed =
    updatePermission?.data?.has_permission && !enterpriseMode

  const { visible, openModal, closeModal: close } = useModal(false)

  const sites = useMemo(
    () => portals.filter((portal) => portal.type === PortalTypes.Site),
    [portals]
  )

  const filtersList = useMemo(
    () => [
      dateRangeFilter,
      ...(enterpriseMode ? [siteFilter(sites)] : []),
      weightClassFilter,
      fuelTypeFilter,
      emissionTypeFilter,
      mismatchFilter
    ],
    [sites]
  )

  const columns = useCallback(
    (groupBy: string | undefined) => isrColumns(groupBy, enterpriseMode, sites),
    [sites, enterpriseMode]
  )

  const highOrderColumns = useCallback(
    (groupBy: string | undefined) =>
      isrHighOrderColumns(groupBy, enterpriseMode),
    [enterpriseMode]
  )

  const closeModal = () => {
    close()
    setSelectedItem(undefined)
  }

  const handleAction = (action: TableActions, row: IsrEventPair) => {
    switch (action) {
      case TableActions.RowClick: {
        if (!isUpdateAllowed) return

        openModal()
        setSelectedItem(row)
        break
      }

      default: {
        break
      }
    }
  }

  const Rollups = (
    <>
      <RollupStat
        label="Total # of Visits"
        value={pagination?.totalItemsCount || 0}
      />
      <RollupStat
        label="High Emission Visits"
        value={emissionDataCount}
        loading={loadingEmissions}
        filter={{
          powerUnitEmissionType: [EmissionType.E]
        }}
      />
      <RollupStat
        label="Near Zero Emission Visits"
        value={nearZeroEmissionDataCount}
        loading={loadingNearZeroEmissions}
        filter={{
          powerUnitEmissionType: [EmissionType.NZE]
        }}
      />
      <RollupStat
        label="Zero Emission Visits"
        value={zeroEmissionDataCount}
        loading={loadingZeroEmissions}
        filter={{
          powerUnitEmissionType: [EmissionType.ZE]
        }}
      />
    </>
  )

  const TotalVisitsChart = (
    <TimeseriesChart
      chartTitle="Total Visit Volume"
      subHeader="25% increase vs. last month's January visits"
      timeseriesHeaderRightLabel={{
        label: 'Current Month',
        color: ROLLUP_WIDGET_COLORS[0]
      }}
    />
  )

  const CurrentTimeseriesChart = useMemo(() => {
    // No filters applied
    if (filters === undefined) return TotalVisitsChart

    // Filters object present but not necessarily emission filters
    const keys = Object.keys(filters)
    const emissionFilterId = emissionTypeFilter.id
    if (!keys.includes(emissionFilterId)) return TotalVisitsChart

    // Emission filter(s) are present
    const emissionTypes = filters[emissionFilterId].values
    if (emissionTypes?.length !== 1) return <></>

    const firstEmissionType = emissionTypes?.[0]

    if (firstEmissionType === EmissionType.E) {
      return <TimeseriesChart chartTitle="High Emission Visits" />
    } else if (firstEmissionType === EmissionType.NZE) {
      return <TimeseriesChart chartTitle="Near Zero Emission Visits" />
    } else if (firstEmissionType === EmissionType.ZE) {
      return <TimeseriesChart chartTitle="Zero Emission Visits" />
    }
  }, [filters])

  const VisitsByEmissionPieChart = (
    <PieChart
      headerText={'Visits By Emission Type'}
      subHeaderText={"25% increase vs. last month's January visits"}
      totalLabel={'Visits'}
      data={[
        {
          title: 'High Emission',
          value: 123
        },
        {
          title: 'Near Zero Emission',
          value: 42
        },
        {
          title: 'Zero Emission',
          value: 18
        }
      ]}
    />
  )

  const Statistics = (
    <>
      {CurrentTimeseriesChart}
      {filters === undefined && VisitsByEmissionPieChart}
    </>
  )

  return (
    <WithHeaderTemplate title="ISR">
      <TerminalDocumentTitle title="ISR" />

      {visible && (
        <UpdateIsrReportModal
          item={selectedItem as IsrEventPair}
          closeModal={closeModal}
          refetch={refetch}
        />
      )}

      <div className="tw-flex-1 tw-flex">
        <Table
          withSearch
          name={enterpriseMode ? ENTERPRISE_TABLE_ID : TABLE_ID}
          rows={reports}
          clickable={isUpdateAllowed}
          loading={loading || enterpriseLoading || isFetching}
          filters={filtersList}
          columns={columns}
          columnsVersion={ISR_REPORTS_COLUMNS_VERSION}
          handleAction={handleAction}
          highOrderColumns={highOrderColumns}
          searchPlaceholder="Search Transaction"
          perPage={PAGINATION_ITEMS_PER_PAGE}
          currentPage={currentPage}
          totals={{
            items: pagination?.totalItemsCount,
            pages: pagination?.totalPages
          }}
          onUpdateRequestDetails={setRequestDetails}
          noResultsMessage={{
            Icon: <IsrData />,
            primaryText: 'No ISR data available',
            descriptionText:
              'Once power units are checked in, ISR data will appear here'
          }}
          Rollups={Rollups}
          Statistics={Statistics}
        />
      </div>
    </WithHeaderTemplate>
  )
}

export default ISRTemplate
