import { Loader } from '@/components/atoms'
import { useMobileNavigationContext } from '@/components/contexts'
import { Navigation } from '@/components/organisms'
import { ErrorTemplate } from '@/components/templates'
import {
  useLazyCheckMyPermissionsQuery,
  useLazyFetchMySitesQuery
} from '@/features/auth/api'
import {
  GateRelation,
  OrganizationRelation,
  PermissionResourceType,
  SiteRelation
} from '@/features/auth/enums'
import {
  getInitialGate,
  preparePermissionResourceQuery
} from '@/features/auth/helpers'
import {
  updatePortals,
  updateSitePermissionsAndGate,
  updateUserDetails
} from '@/features/auth/store'
import {
  Jwt,
  PermissionResource,
  SitePortalPermissions
} from '@/features/auth/types'
import {
  useLazyFetchGatesQuery,
  useLazyFetchLanesQuery
} from '@/features/gate/api'
import { GateWithLanes } from '@/features/gate/types'
import { ROUTES, useNavigator } from '@/router'
import { useDispatch, useStore } from '@/store'
import { PortalTypes } from '@/types/enums/global'
import { ErrorType } from '@/types/enums/ui'
import { findPageToRedirect, getAvailableLinksPerPortal } from '@/utils/helpers'
import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { jwtDecode } from 'jwt-decode'
import { useEffect, useLayoutEffect, useState } from 'react'
import {
  Outlet,
  useLocation,
  useParams,
  useSearchParams
} from 'react-router-dom'

import { AppBannerProvider } from '@/components/contexts/AppBanner/AppBannerContext'
import { DEFAULT_SITE_PERMISSIONS } from '@/features/auth/constants'
import { ENTERPRISE_ROUTE_PREFIX } from '@/router/routes'
import styles from './MainTemplate.module.scss'

const MainTemplate = () => {
  const navigate = useNavigator()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { site_id } = useParams()

  const { toggleMobileNavigation } = useMobileNavigationContext()

  const dispatch = useDispatch()
  const { selectedPortal, selectedGate, gates, org } = useStore(
    (store) => store.user
  )

  const [fetchMySites] = useLazyFetchMySitesQuery()
  const [checkPermission] = useLazyCheckMyPermissionsQuery()
  const [fetchGates] = useLazyFetchGatesQuery()
  const [fetchLanes] = useLazyFetchLanesQuery()

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0()

  const [isFetchingProfileData, setFetchingProfileData] =
    useState<boolean>(false)

  const path = location.pathname
  const isFetchingPermissions = !selectedPortal?.permissions
  const isFetchingGates =
    selectedPortal?.type === PortalTypes.Site && !Array.isArray(gates)

  const login = () => {
    const invitation = searchParams.get('invitation')
    const organization = searchParams.get('organization')

    const appState = {
      returnTo: `${window.location.pathname}${window.location.search}`
    }

    if (invitation && organization) {
      loginWithRedirect({
        authorizationParams: {
          organization,
          invitation
        },
        appState
      })

      return
    }

    loginWithRedirect({ appState })
  }

  const getUserInfo = async () => {
    try {
      setFetchingProfileData(() => true)

      const token = await getAccessTokenSilently({})
      const decodedJwt: Jwt = jwtDecode(token)

      const { org_id: orgId } = decodedJwt

      await dispatch(updateUserDetails(token))

      const sitesResponse = await fetchMySites({
        orgId
      })

      const orgPortalEnabled = await checkPermission({
        orgId,
        relation: OrganizationRelation.EnterprisePortal,
        resource: preparePermissionResourceQuery(
          PermissionResourceType.Organization,
          orgId
        )
      })

      await dispatch(
        updatePortals({
          sites: sitesResponse?.data?.data?.sites || [],
          enterpriseEnabled: !!orgPortalEnabled?.data?.data?.has_permission,
          portalIdFromUrl: path.includes(ENTERPRISE_ROUTE_PREFIX)
            ? PortalTypes.Enterprise
            : site_id
        })
      )
    } catch {
      await dispatch(
        updatePortals({
          sites: [],
          enterpriseEnabled: false
        })
      )
    } finally {
      setFetchingProfileData(() => false)
    }
  }

  const checkIfUserCanAccessPage = () => {
    // No need to auto redirect if user is on profile page and portal is not changed
    const isProfilePage = location.pathname === ROUTES.PROFILE

    if (isFetchingPermissions || isFetchingGates || isProfilePage) return

    // When we know there are no gates per site, show a generic error message on the homepage
    if (Array.isArray(gates) && !gates.length) {
      navigate.toHome()
      return
    }

    const allowedLinks = getAvailableLinksPerPortal(
      selectedPortal,
      selectedGate?.id || ''
    )

    if (!allowedLinks.includes(location.pathname)) {
      navigate.to(
        findPageToRedirect({
          selectedPortal,
          allowedLinks
        })
      )
    }
  }

  const getSitePermissions = async () => {
    const isEnterpriseSelected = selectedPortal?.type === PortalTypes.Enterprise
    const isPermissionsFetched = !!selectedPortal?.permissions

    if (!org || !selectedPortal || isPermissionsFetched || isEnterpriseSelected)
      return

    const siteId = selectedPortal.id
    const orgId = org.organization_id

    const gateResponse = await fetchGates({
      org_id: orgId,
      site_id: siteId
    })

    const gates = gateResponse?.data?.data?.gates || []

    // If there are no gates for selected site then user has no access to anything within this site
    if (!gates.length) {
      await dispatch(
        updateSitePermissionsAndGate({
          portalId: siteId,
          gates: [],
          selectedGate: undefined,
          permissions: { ...DEFAULT_SITE_PERMISSIONS }
        })
      )

      return
    }

    // API endpoint doesn't provide lanes list per each gate, but we need to know them
    // to be able to determine which gate the transaction belongs to for sites with multiple gates
    const lanes = await Promise.all(
      gates.map((gateItem) =>
        fetchLanes({ org_id: orgId, site_id: siteId, gate_id: gateItem.id })
      )
    )

    const gatesWithInjectedLanes: GateWithLanes[] = gates.map(
      (gateItem, index) => ({
        ...gateItem,
        lanes: lanes[index]?.data?.data?.lanes || []
      })
    )

    const gate = getInitialGate(gatesWithInjectedLanes)

    const siteResource = preparePermissionResourceQuery(
      PermissionResourceType.Site,
      siteId
    )

    const permissionsToCheck: {
      [key in keyof SitePortalPermissions]?: {
        relation: string
        resource: PermissionResource
      }
    } = {
      gate_queue: {
        relation: GateRelation.Read,
        resource: preparePermissionResourceQuery(
          PermissionResourceType.Gate,
          gate.id
        )
      },
      gate_transaction: {
        relation: SiteRelation.GateTransactionsList,
        resource: siteResource
      },
      onsite_driver: {
        relation: SiteRelation.DriversOnsiteList,
        resource: siteResource
      },
      onsite_cargo_asset: {
        relation: SiteRelation.CargoAssetsOnsiteList,
        resource: siteResource
      },
      isr: {
        relation: SiteRelation.ISRRecordsList,
        resource: siteResource
      }
    }

    const permissionsResponse = await Promise.all(
      Object.entries(permissionsToCheck).map(([, permissionRequest]) =>
        checkPermission({
          orgId,
          relation: permissionRequest.relation,
          resource: permissionRequest.resource
        })
      )
    )

    const permissions: SitePortalPermissions = Object.keys(
      permissionsToCheck
    ).reduce(
      (result: SitePortalPermissions, permissionKey, index: number) => {
        result[permissionKey as keyof SitePortalPermissions] =
          permissionsResponse?.[index]?.data?.data?.has_permission || false

        return result
      },
      { ...DEFAULT_SITE_PERMISSIONS }
    )

    await dispatch(
      updateSitePermissionsAndGate({
        portalId: siteId,
        gates: gatesWithInjectedLanes,
        selectedGate: gate,
        permissions
      })
    )
  }

  useEffect(() => {
    if (isLoading) return

    if (isAuthenticated) {
      getUserInfo()
      return
    }

    login()
  }, [isAuthenticated, isLoading])

  useEffect(() => {
    getSitePermissions()
  }, [selectedPortal])

  useLayoutEffect(() => {
    checkIfUserCanAccessPage()
  }, [location.pathname, selectedPortal])

  useEffect(() => {
    toggleMobileNavigation(false)
  }, [location.pathname])

  // If user has access to any portal we need to wait for the permissions and gates to be fetched
  if (
    !isAuthenticated ||
    isFetchingProfileData ||
    isFetchingPermissions ||
    isFetchingGates
  ) {
    return <Loader fullScreen />
  }

  return (
    <div className={styles.overallWrapper}>
      <AppBannerProvider>
        <div className={styles.wrapper}>
          <Navigation />

          <Sentry.ErrorBoundary
            key={path}
            fallback={<ErrorTemplate type={ErrorType.Generic} />}
          >
            <Outlet />
          </Sentry.ErrorBoundary>
        </div>
      </AppBannerProvider>
    </div>
  )
}

export default MainTemplate
