import { useQuery } from '@apollo/client'
import { FC, useMemo, useState } from 'react'

import {
  GateTransaction,
  ListGateTransactionEventsInput,
  OrderByDomainEventColumn,
  SearchDomainEventColumns,
  StringOperator
} from '@/__generated__/graphql'
import {
  GetEnterpriseGateTransactionsQuery,
  GetGateTransactionsQuery
} from '@/features/gate/api/queries'

import GateTransactions from '@/assets/icons/gate_transactions.svg?react'
import { Table } from '@/components/organisms'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { useFetchAccountsQuery } from '@/features/gate/api'
import {
  TRANSACTIONS_COLUMNS_VERSION,
  transactionsColumns,
  transactionsHighOrderColumns
} from '@/features/gate/utils'
import { useStore } from '@/store'

import { prepareSortForGraphQlApi } from '@/api'
import { WithHeaderTemplate } from '@/components/templates'
import { PortalTypes } from '@/types/enums/global'
import {
  DEFAULT_TABLE_REQUEST_DETAILS,
  TableRequestDetails
} from '@/types/interfaces/table'
import {
  accountFilter,
  appointmentTypeFilter,
  dateRangeFilter,
  emissionTypeFilter,
  eventTypeFilter,
  fuelTypeFilter,
  loadStatusFilter,
  mismatchFilter,
  siteFilter,
  transactionDirectionFilter,
  weightClassFilter
} from '@/utils/table'
import GateTransactionDetailsModal from '@/features/gate/components/GateTransactionDetailsModal/GateTransactionDetailsModal'
import { useModal } from '@/hooks'
import { TableActions } from '@/types/enums/table'

import TerminalDocumentTitle from '@/components/contexts/Title/TerminalDocumentTitle'
import RollupStat from '@/components/organisms/Table/Rollups/Stats/RollupStat'
import TimeseriesChart from '@/components/organisms/Table/Rollups/Timeseries/TimeseriesChart'
import { DomainTransactionTypes } from '../../enums'
import useGateTransactionRollups from '../../hooks/useGateTransactionRollups'
import styles from './GateTransactionTemplate.module.scss'

interface Props {
  enterpriseMode?: boolean
}

const TABLE_ID = 'gate-transactions'
const MULTIPLE_GATES_TABLE_ID = 'multiple-gates-transactions'
const ENTERPRISE_TABLE_ID = 'enterprise-transactions'

const GateTransactionTemplate: FC<Props> = (props) => {
  const { enterpriseMode = false } = props
  const {
    selectedPortal,
    org,
    portals,
    gates = []
  } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [
    { currentPage, search, sortBy, filters, dateRange },
    setRequestDetails
  ] = useState<TableRequestDetails>(DEFAULT_TABLE_REQUEST_DETAILS)

  const [selectedItem, setSelectedItem] = useState<
    GateTransaction | undefined
  >()

  const { siteIDs, ...remainingFilters } = filters || {}

  const { data: accounts = [] } = useFetchAccountsQuery(
    {
      site_id,
      org_id
    },
    { skip: !site_id || enterpriseMode }
  )

  const baseQueryInput: Omit<ListGateTransactionEventsInput, 'siteID'> = {
    organizationID: org_id,
    currentPage,
    itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
    search: {
      fields: [
        SearchDomainEventColumns.PowerUnitOwnerId,
        SearchDomainEventColumns.PowerUnitLicensePlateNumber,
        SearchDomainEventColumns.PowerUnitCarrierUsdot,
        SearchDomainEventColumns.PowerUnitCarrierMcnum,
        SearchDomainEventColumns.PowerUnitVin,
        SearchDomainEventColumns.PowerUnitCarrierName,
        SearchDomainEventColumns.AccountName,
        SearchDomainEventColumns.DriverName,
        SearchDomainEventColumns.DriverLicenseNumber,
        SearchDomainEventColumns.DriverPhoneNumber,
        SearchDomainEventColumns.CargoAssetOwnerId,
        SearchDomainEventColumns.CargoAssetLicensePlateNumber,
        SearchDomainEventColumns.CargoAssetCarrierName,
        SearchDomainEventColumns.ShipmentNumber,
        SearchDomainEventColumns.SealNumbers
      ],
      operator: StringOperator.Contains,
      value: search
    },
    order: prepareSortForGraphQlApi<OrderByDomainEventColumn>(sortBy),
    filter: remainingFilters || undefined,
    dateRange
  }

  const {
    loadingCheckInData,
    checkInDataCount,
    loadingCheckOutData,
    checkOutDataCount
  } = useGateTransactionRollups({
    query: baseQueryInput,
    enterpriseMode,
    filters: remainingFilters
  })
  const { visible, openModal, closeModal } = useModal(false)

  const { loading, data } = useQuery(GetGateTransactionsQuery, {
    variables: {
      input: {
        siteID: site_id,
        ...baseQueryInput
      }
    },
    skip: enterpriseMode
  })

  const { loading: enterpriseLoading, data: enterpriseData } = useQuery(
    GetEnterpriseGateTransactionsQuery,
    {
      variables: {
        input: {
          siteIDs: siteIDs?.values,
          ...baseQueryInput
        }
      },
      skip: !enterpriseMode
    }
  )

  const transactions = enterpriseMode
    ? enterpriseData?.listEnterpriseGateTransactions?.events || []
    : data?.listGateTransactions?.events || []

  const pagination = enterpriseMode
    ? enterpriseData?.listEnterpriseGateTransactions?.pagination
    : data?.listGateTransactions?.pagination

  const hasMultipleGates = gates.length > 1

  const sites = useMemo(
    () => portals.filter((portal) => portal.type === PortalTypes.Site),
    [portals]
  )

  const filtersList = useMemo(
    () => [
      dateRangeFilter,
      ...(enterpriseMode ? [siteFilter(sites)] : []),
      transactionDirectionFilter,
      eventTypeFilter,
      appointmentTypeFilter,
      weightClassFilter,
      fuelTypeFilter,
      emissionTypeFilter,
      loadStatusFilter,
      ...(!enterpriseMode ? [accountFilter(accounts)] : []),
      mismatchFilter
    ],
    [accounts, sites]
  )

  const { columns, highOrderColumns, tableId } = useMemo(() => {
    const sitePortalTableId = hasMultipleGates
      ? MULTIPLE_GATES_TABLE_ID
      : TABLE_ID

    return {
      tableId: enterpriseMode ? ENTERPRISE_TABLE_ID : sitePortalTableId,

      columns: () =>
        transactionsColumns({
          enterpriseMode,
          sites,
          gates
        }),

      highOrderColumns: () =>
        transactionsHighOrderColumns(enterpriseMode || hasMultipleGates)
    }
  }, [enterpriseMode, sites, gates])

  const handleAction = (action: TableActions, row: GateTransaction) => {
    switch (action) {
      case TableActions.RowClick: {
        setSelectedItem(row)
        openModal()
        break
      }

      default: {
        break
      }
    }
  }

  const Rollups = (
    <>
      <RollupStat
        label={'Total Gate Transactions'}
        loading={loading}
        value={pagination?.totalItemsCount || 0}
      />
      <RollupStat
        label={'Total Check-Ins'}
        loading={loadingCheckInData}
        value={checkInDataCount}
        filter={{
          transactionType: [DomainTransactionTypes.CheckIn]
        }}
      />
      <RollupStat
        label={'Total Check-Outs'}
        loading={loadingCheckOutData}
        value={checkOutDataCount}
        filter={{
          transactionType: [DomainTransactionTypes.CheckOut]
        }}
      />
    </>
  )

  const Statistics = (
    <>
      <TimeseriesChart chartTitle="Transactions by PU Type" />
      <TimeseriesChart chartTitle="Transactions by Cargo Asset Type" />
    </>
  )

  const GateTransactionsTable = (
    <div className={styles.gateTransactionTemplate}>
      <TerminalDocumentTitle title="Transactions" />
      <div className="tw-flex-1 tw-flex">
        <Table
          withSearch
          clickable
          name={tableId}
          loading={loading || enterpriseLoading}
          rows={transactions}
          filters={filtersList}
          columns={columns}
          handleAction={handleAction}
          columnsVersion={TRANSACTIONS_COLUMNS_VERSION}
          highOrderColumns={highOrderColumns}
          searchPlaceholder="Search Transaction"
          perPage={PAGINATION_ITEMS_PER_PAGE}
          currentPage={currentPage}
          totals={{
            items: pagination?.totalItemsCount,
            pages: pagination?.totalPages
          }}
          onUpdateRequestDetails={setRequestDetails}
          noResultsMessage={{
            primaryText: 'No gate transactions',
            descriptionText:
              'Gate Check-In and Check-Out transactions will appear here.',
            Icon: <GateTransactions />
          }}
          Rollups={Rollups}
          Statistics={Statistics}
        />
      </div>
    </div>
  )

  return (
    <>
      {visible && !!selectedItem && (
        <GateTransactionDetailsModal
          transaction={selectedItem}
          closeModal={closeModal}
        />
      )}

      {enterpriseMode ? (
        <WithHeaderTemplate title="Gate Transactions">
          {GateTransactionsTable}
        </WithHeaderTemplate>
      ) : (
        GateTransactionsTable
      )}
    </>
  )
}

export default GateTransactionTemplate
