import AddIcon from '@mui/icons-material/Add'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { CSSProperties, FC, useEffect, useMemo, useState } from 'react'

import { Button, Row, Text } from '@/components/atoms'
import { Tabs } from '@/components/molecules'
import { FullSpaceTemplate } from '@/components/templates'
import {
  CheckInModal,
  CheckOutModal,
  DeleteQueueItemModal,
  GateQueue
} from '@/features/gate'
import {
  useDeleteQueueEventMutation,
  useFetchQueueEventsQuery
} from '@/features/gate/api'
import { IgnoredAssetTypes, LaneDirection } from '@/features/gate/enums'
import { GateQueueEvent, Lane } from '@/features/gate/types'
import { getLaneDirectionName } from '@/features/gate/utils'
import { useSnackbar } from '@/features/snackbars-queue'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { ITab } from '@/types/interfaces/ui'

import { useCheckMyPermissionsQuery } from '@/features/auth/api'
import { GateRelation, PermissionResourceType } from '@/features/auth/enums'
import { preparePermissionResourceQuery } from '@/features/auth/helpers'

import useViewportSize from '@/hooks/useViewportSize'
import { StatusCodes } from 'http-status-codes'
import ExpiredSessionModal from './ExpiredSessionModal'
import styles from './Queues.module.scss'
import { useParams } from 'react-router-dom'

const Queues: FC = () => {
  const { showDefaultSnackbar } = useSnackbar()
  const { org, selectedGate } = useStore((store) => store.user)
  const { organization_id = '' } = org || {}

  const { lanes = [], id: gateId = '' } = selectedGate || {}
  const { site_id } = useParams()

  const POLLING_INTERVAL = 5000

  const [sessionExpired, setSessionExpired] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<string | undefined>()
  // We store here cards moved from one lane to another since BE doesn't support this feature
  const [movedItems, setMovedItems] = useState<{
    [key in LaneDirection]: GateQueueEvent[]
  }>({
    [LaneDirection.Arriving]: [],
    [LaneDirection.Departing]: []
  })
  const [selectedItem, setSelectedItem] = useState<{
    event: GateQueueEvent | undefined
    lane: Lane | undefined
  }>({
    event: undefined,
    lane: undefined
  })

  const { isSmallView } = useViewportSize()

  const { visible, ...modalHelpers } = useModal<LaneDirection | 'delete'>()

  const { currentData: checkInPermission } = useCheckMyPermissionsQuery({
    orgId: organization_id,
    relation: GateRelation.CheckIn,
    resource: preparePermissionResourceQuery(
      PermissionResourceType.Gate,
      gateId
    )
  })

  const { currentData: checkOutPermission } = useCheckMyPermissionsQuery({
    orgId: organization_id,
    relation: GateRelation.CheckOut,
    resource: preparePermissionResourceQuery(
      PermissionResourceType.Gate,
      gateId
    )
  })

  const { arrivingLane, departingLane } = useMemo(
    () => ({
      arrivingLane: lanes.find(
        (lane) => lane.direction === LaneDirection.Arriving
      ),
      departingLane: lanes.find(
        (lane) => lane.direction === LaneDirection.Departing
      )
    }),
    [lanes]
  )

  const { data: arrivingData } = useFetchQueueEventsQuery(
    {
      org_id: organization_id,
      site_id: site_id as string,
      lane_id: arrivingLane?.id as string,
      direction: LaneDirection.Arriving,
      ignored_asset_types: [
        IgnoredAssetTypes.Personal,
        IgnoredAssetTypes.YardSpotter
      ]
    },
    {
      skip: !organization_id || !arrivingLane?.id || sessionExpired,
      pollingInterval: POLLING_INTERVAL
    }
  )

  const { data: departingData } = useFetchQueueEventsQuery(
    {
      org_id: organization_id,
      site_id: site_id as string,
      lane_id: departingLane?.id as string,
      direction: LaneDirection.Departing,
      ignored_asset_types: [
        IgnoredAssetTypes.Personal,
        IgnoredAssetTypes.YardSpotter
      ]
    },
    {
      skip: !organization_id || !departingLane?.id || sessionExpired,
      pollingInterval: POLLING_INTERVAL
    }
  )

  const [deleteEvent, { isLoading: isDeleting }] = useDeleteQueueEventMutation()

  const isCheckInAllowed = !!checkInPermission?.data?.has_permission
  const isCheckOutAllowed = !!checkOutPermission?.data?.has_permission

  useEffect(() => {
    const arrivingStatus = arrivingData?.status
    const departingStatus = departingData?.status
    if (
      arrivingStatus === StatusCodes.UNAUTHORIZED ||
      departingStatus === StatusCodes.UNAUTHORIZED
    ) {
      setSessionExpired(true)
    }
  }, [arrivingData, departingData])

  const {
    arrivingCards,
    departingCards,
    totalArrivingCount,
    totalDepartingCount
  } = useMemo(() => {
    const arriving = arrivingData?.data || []
    const departing = departingData?.data || []

    return {
      arrivingCards: [
        ...movedItems[LaneDirection.Arriving],
        ...arriving.filter(
          (item) =>
            !movedItems[LaneDirection.Departing].some((i) => i.id === item.id)
        )
      ],

      departingCards: [
        ...movedItems[LaneDirection.Departing],
        ...departing.filter(
          (item) =>
            !movedItems[LaneDirection.Arriving].some((i) => i.id === item.id)
        )
      ],

      totalArrivingCount:
        (arrivingData?.pagination?.total_items_count || 0) +
        movedItems.ARRIVING.length -
        movedItems.DEPARTING.length,

      totalDepartingCount:
        (departingData?.pagination?.total_items_count || 0) +
        movedItems.DEPARTING.length -
        movedItems.ARRIVING.length
    }
  }, [movedItems, arrivingData, departingData])

  const openModal = (
    event: GateQueueEvent | undefined,
    lane: Lane,
    deleteItem?: boolean
  ) => {
    setSelectedItem({ event, lane })
    modalHelpers.openModal(deleteItem ? 'delete' : lane.direction)
  }

  const closeModal = (removeFromMovedItems?: boolean) => {
    if (removeFromMovedItems) {
      setMovedItems((prev) => ({
        [LaneDirection.Arriving]: prev[LaneDirection.Arriving].filter(
          (i) => i.id !== selectedItem.event?.id
        ),
        [LaneDirection.Departing]: prev[LaneDirection.Departing].filter(
          (i) => i.id !== selectedItem.event?.id
        )
      }))
    }

    setSelectedItem({ event: undefined, lane: undefined })
    modalHelpers.closeModal()
  }

  const handleDelete = async (reason: string) => {
    if (!selectedItem.event || !gateId) return

    await deleteEvent({
      org_id: selectedItem.event.organization_id,
      gate_id: gateId,
      lane_id: selectedItem.event.lane_id,
      id: selectedItem.event.correlation_id,
      reason
    })

    closeModal(true)
  }

  const onManually = () => {
    if (!activeTab) return

    const selectedLane = lanes.find((lane) => lane.id === activeTab)

    if (!selectedLane) return

    openModal(undefined, selectedLane)
  }

  const onSwapLane = (
    item: GateQueueEvent,
    to: LaneDirection,
    reopenModal?: boolean
  ) => {
    const currentLane =
      to === LaneDirection.Arriving ? departingLane : arrivingLane
    const newLane = to === LaneDirection.Arriving ? arrivingLane : departingLane

    if (!newLane || !currentLane) return

    const currentLaneDirection = currentLane.direction

    const alreadyMovedItem = movedItems[currentLaneDirection].find(
      (i) => i.id === item.id
    )

    // If items is already moved to the opposite direction just remove it from there
    if (alreadyMovedItem) {
      setMovedItems((prev) => ({
        ...prev,
        [currentLaneDirection]: prev[currentLaneDirection].filter(
          (i) => i.id !== item.id
        )
      }))
    } else {
      setMovedItems((prev) => ({
        ...prev,
        [to]: [item, ...prev[to]]
      }))
    }

    const newDirection = getLaneDirectionName(to)

    showDefaultSnackbar(`Successfully moved to ${newDirection}`, {
      action: !reopenModal && (
        <Text
          type={TextTypes.TEXT_SM}
          weight={FontWeight.SEMIBOLD}
          color={Color.green500}
          onClick={() => openModal(item, newLane)}
          className="tw-cursor-pointer"
        >
          {`${newDirection} asset`}
        </Text>
      )
    })

    if (reopenModal) {
      openModal(item, newLane)
    }
  }

  const CheckInQueue = useMemo(
    () =>
      arrivingLane ? (
        <GateQueue
          lane={arrivingLane}
          isActionAllowed={isCheckInAllowed}
          type={LaneDirection.Arriving}
          items={arrivingCards}
          onSwapLane={onSwapLane}
          openModal={openModal}
          totalCount={totalArrivingCount}
        />
      ) : undefined,
    [arrivingLane, arrivingCards, isCheckInAllowed]
  )

  const CheckOutQueue = useMemo(
    () =>
      departingLane ? (
        <GateQueue
          lane={departingLane}
          isActionAllowed={isCheckOutAllowed}
          type={LaneDirection.Departing}
          items={departingCards}
          onSwapLane={onSwapLane}
          openModal={openModal}
          totalCount={totalDepartingCount}
        />
      ) : undefined,
    [departingLane, departingCards, isCheckOutAllowed]
  )

  const tabs: ITab[] = [
    ...(arrivingLane
      ? [
          {
            id: arrivingLane.id,
            title: 'Check-In',
            icon: <LoginOutlinedIcon />,
            items: totalArrivingCount,
            Component: CheckInQueue
          }
        ]
      : []),

    ...(departingLane
      ? [
          {
            id: departingLane.id,
            title: 'Check-Out',
            icon: <LogoutOutlinedIcon />,
            items: totalDepartingCount,
            Component: CheckOutQueue
          }
        ]
      : [])
  ]

  useEffect(() => {
    setActiveTab(arrivingLane?.id || departingLane?.id)
  }, [arrivingLane, departingLane])

  return (
    <Row className="tw-flex-1 tw-h-full">
      {sessionExpired && <ExpiredSessionModal />}

      {visible === LaneDirection.Arriving && (
        <CheckInModal
          item={selectedItem.event}
          lane={selectedItem.lane as Lane}
          closeModal={closeModal}
          onSwapLane={onSwapLane}
        />
      )}

      {visible === LaneDirection.Departing && (
        <CheckOutModal
          item={selectedItem.event}
          lane={selectedItem.lane as Lane}
          closeModal={closeModal}
          onSwapLane={onSwapLane}
        />
      )}

      {visible === 'delete' && (
        <DeleteQueueItemModal
          loading={isDeleting}
          closeModal={closeModal}
          onDelete={handleDelete}
        />
      )}

      {isSmallView ? (
        <FullSpaceTemplate>
          <Tabs
            sticky
            countAsBadge
            active={activeTab}
            setActive={setActiveTab}
            tabs={tabs}
            headerClassName="tw-px-16"
            contentClassName="background-color-gray100"
            Actions={
              <div className={styles.tabActionWrapper}>
                <Button
                  square
                  type="outlined"
                  disabled={!isCheckInAllowed || !isCheckOutAllowed}
                  onClick={onManually}
                >
                  <AddIcon />
                </Button>
              </div>
            }
          />
        </FullSpaceTemplate>
      ) : (
        <div
          className={styles.queues}
          // We need this to dynamically generate the grid columns
          style={{ '--count': lanes.length || 1 } as CSSProperties}
        >
          {CheckInQueue}
          {CheckOutQueue}
        </div>
      )}
    </Row>
  )
}

export default Queues
